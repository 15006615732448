<!-- src/components/PWAPrompt.vue -->
<template>
  <div class="fixed bottom-0 left-0 right-0 z-50">
    <Transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="transform translate-y-full opacity-0"
      enter-to-class="transform translate-y-0 opacity-100"
      leave-active-class="transition ease-in duration-200"
      leave-from-class="transform translate-y-0 opacity-100"
      leave-to-class="transform translate-y-full opacity-0"
    >
      <div
        v-if="showInstallPrompt && isMobileDevice && !isStandalone"
        class="bg-white border-t shadow-lg p-4 flex items-center justify-between"
      >
        <div class="flex items-center">
          <Download class="w-6 h-6 text-custom-buttonPrimary mr-3" />
          <div>
            <h3 class="font-semibold">{{ $t('pwa.install_title') }}</h3>
            <p class="text-sm text-gray-600">{{ $t('pwa.install_subtitle') }}</p>
          </div>
        </div>
        <div class="flex items-center gap-2">
          <button
            @click="dismissInstallPrompt"
            class="p-2 hover:bg-gray-100 rounded-full"
            :aria-label="$t('pwa.dismiss')"
          >
            <X class="w-5 h-5" />
          </button>
          <button
            @click="handleInstall"
            class="bg-custom-buttonPrimary text-white px-4 py-2 rounded-full hover:bg-custom-buttonPrimaryHover"
          >
            {{ $t('pwa.install_button') }}
          </button>
        </div>
      </div>
    </Transition>

    <Transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="transform translate-y-full opacity-0"
      enter-to-class="transform translate-y-0 opacity-100"
      leave-active-class="transition ease-in duration-200"
      leave-from-class="transform translate-y-0 opacity-100"
      leave-to-class="transform translate-y-full opacity-0"
    >
      <div
        v-if="showUpdateNotification"
        class="bg-white border-t shadow-lg p-4 flex items-center justify-between"
      >
        <div class="flex items-center">
          <Bell class="w-6 h-6 text-custom-buttonPrimary mr-3" />
          <div>
            <h3 class="font-semibold">{{ $t('pwa.update_title') }}</h3>
            <p class="text-sm text-gray-600">{{ $t('pwa.update_subtitle') }}</p>
          </div>
        </div>
        <div class="flex items-center gap-2">
          <button
            @click="dismissUpdateNotification"
            class="p-2 hover:bg-gray-100 rounded-full"
            :aria-label="$t('pwa.dismiss')"
          >
            <X class="w-5 h-5" />
          </button>
          <button
            @click="handleUpdate"
            class="bg-custom-buttonPrimary text-white px-4 py-2 rounded-full hover:bg-custom-buttonPrimaryHover"
          >
            {{ $t('pwa.update_button') }}
          </button>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { Bell, Download, X } from 'lucide-vue-next'

const installPromptEvent = ref(null)
const showInstallPrompt = ref(false)
const showUpdateNotification = ref(false)

// Check if device is mobile
const isMobileDevice = computed(() => {
  if (typeof window === 'undefined') return false
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
})

// Check if app is already installed
const isStandalone = computed(() => {
  if (typeof window === 'undefined') return false
  return (
    window.matchMedia('(display-mode: standalone)').matches ||
    window.navigator.standalone || // iOS
    document.referrer.includes('android-app://')
  )
})

// Check if browser supports PWA installation
const isPWASupported = computed(() => {
  return 'serviceWorker' in navigator && 'BeforeInstallPromptEvent' in window
})

onMounted(() => {
  // Only set up PWA handlers if supported and on mobile
  if (isPWASupported.value && isMobileDevice.value && !isStandalone.value) {
    // Handle PWA install prompt
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      installPromptEvent.value = e

      // Only show prompt if not already installed
      if (!isStandalone.value) {
        showInstallPrompt.value = true
      }
    })

    // Handle PWA updates
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        showUpdateNotification.value = true
      })
    }

    // Check if already installed via display-mode
    window.matchMedia('(display-mode: standalone)').addListener((e) => {
      if (e.matches) {
        showInstallPrompt.value = false
      }
    })
  }
})

const handleInstall = async () => {
  if (!installPromptEvent.value) return

  try {
    const result = await installPromptEvent.value.prompt()
    console.log(`Install prompt was: ${result.outcome}`)
    // Hide prompt regardless of outcome
    showInstallPrompt.value = false
  } catch (err) {
    console.error('Error installing PWA:', err)
  }
}

const handleUpdate = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.update()
    })
  }
  showUpdateNotification.value = false
  // Optionally reload the page after a short delay
  setTimeout(() => {
    window.location.reload()
  }, 1000)
}

const dismissInstallPrompt = () => {
  showInstallPrompt.value = false
  // Store in localStorage to prevent showing again for some time
  localStorage.setItem('pwaPromptDismissed', Date.now().toString())
}

const dismissUpdateNotification = () => {
  showUpdateNotification.value = false
}
</script>
