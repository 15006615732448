<!-- src/views/HomeView.vue -->

<template>
  <main class="container mx-auto px-4 py-4 md:py-8">
    <div class="grid md:grid-cols-2 grid-cols-1 gap-4">
      <div>
        <div class="bg-[#dbdad6] p-8 rounded-lg md:h-[500px]">
          <div class="max-w-xl flex flex-col h-full">
            <div class="xl:text-6xl md:leading-tight text-4xl font-black mb-4">
              {{ $t('home.main_h1') }}
            </div>
            <p class="text-gray-600 mt-auto md:text-xl">
              {{ $t('home.main_h2') }}
            </p>
          </div>
        </div>
        <div class="flex gap-4 mt-4 md:h-[300px]">
          <div class="relative rounded-lg grow overflow-hidden">
            <img src="/img/hero01.jpg" alt="Government" class="w-full h-full object-cover" />
            <div class="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-4">
              <h3 class="text-xs md:text-xl font-bold">{{ $t('home.main_forgov') }}</h3>
            </div>
          </div>
          <div class="relative rounded-lg grow overflow-hidden">
            <img src="/img/hero02.jpg" alt="Business" class="w-full h-full object-cover" />
            <div class="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-4">
              <h3 class="text-xs md:text-xl font-bold">{{ $t('home.main_forhealth') }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="rounded-lg overflow-hidden h-full relative">
        <a href="/library">
          <div
            class="absolute bottom-0 right-0 shadow bg-white rounded-full py-4 px-20 m-6 font-bold cursor-pointer text-2xl"
          >
            {{ $t('home.main_getstarted') }}
          </div>
        </a>
        <img src="/img/hero03.jpg" alt="People in a meeting" class="w-full h-full object-cover" />
      </div>
    </div>
  </main>
</template>

<script setup></script>
