import Keycloak from 'keycloak-js'

const initializeKeycloak = async () => {
  try {
    const domain = window.location.hostname;
    const api_url = import.meta.env.VITE_API_BASE_URL || 'http://localhost:1234'
    const response = await fetch(`${api_url}/api/keycloak-config?domain=${domain}`);
    if (!response.ok) {
      throw new Error('Failed to fetch Keycloak configuration')
    }

    const config = await response.json()
    return new Keycloak(config)
  } catch (error) {
    console.error('Failed to initialize Keycloak:', error)
    throw error
  }
}

let keycloak = null
// Initialize a promise that will resolve when Keycloak is ready
let keycloakInitPromise = null
let initializationComplete = false

export const waitForKeycloak = () => {
  if (initializationComplete) {
    return Promise.resolve()
  }
  return keycloakInitPromise
}

export const initKeycloak = async (onAuthenticatedCallback) => {
  if (!keycloak) {
    keycloak = await initializeKeycloak()
  }
  keycloakInitPromise = keycloak.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    // pkceMethod: 'S256',
    checkLoginIframe: false,
    // enableLogging: true
  }).then((authenticated) => {
    if (authenticated) {
      keycloak.onTokenExpired = () => {
        keycloak.updateToken(70).catch(() => {
          console.error('Failed to refresh token');
        });
      };
    }
    initializationComplete = true;
    onAuthenticatedCallback();
    return authenticated;
  }).catch(error => {
    console.error("Keycloak initialization error", error);
    initializationComplete = true; // Even on error, we consider initialization complete
    onAuthenticatedCallback();
    throw error;
  });

  return keycloakInitPromise
}

export const login = () =>
  keycloak.login({
    redirectUri: window.location.origin + '/signin'
  })

export const logout = () => keycloak.logout()

export const getToken = () => keycloak?.token

export const isAuthenticated = () => keycloak?.authenticated || false

export { keycloak }

export default keycloak
